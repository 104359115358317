import React from 'react'

import styled from '@emotion/styled'

import { motion } from 'framer-motion'

import { colors, mq } from 'styles/helpers'

const Tag = styled(motion.div)`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 1px;
  background: ${colors.black};
  transform-origin: top;

  ${mq.tabletP} {
    height: 100vh;
  }
`

const Line = ({ children, transition, ...props }) => {
  const variants = {
    initial: {
      scaleY: 0
    },
    animate: {
      scaleY: 1,
      transition
    }
  }
  return (
    <Tag variants={ variants } />
  )
}

export default Line
