import { bezier } from 'styles/helpers'
import detect from 'helpers/detect'

const base = {
  animate: 'animate',
  exit: 'exit',
  initial: 'initial'
}

const ease = bezier.principleOut
const invert = (v, invert) => v * (invert ? -1 : 1)

const vertical = ({ enter = 0.3, exit = 0.3, inverted = false, enterDelay = null, exitDelay = null, scale = 1 } = {}) => Object.assign({
  variants: {
    initial: { y: invert(10 * scale, inverted), opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { duration: enter, ease, ...(enterDelay && { delay: enterDelay }) } },
    exit: { y: invert(-5 * scale, inverted), opacity: 0, transition: { duration: exit, ease, ...(exitDelay && { delay: exitDelay }) } }
  }
}, base)

const translateAndFadeIn = ({ delay = 0, x = 100 } = {}) => ({ // Y = X2
  variants: {
    initial: { x, opacity: 0 },
    exit: { x: -x, opacity: 0, transition: { default: { duration: 1.2, ease }, x: { duration: 0.6, ease } } },
    animate: { x: 0, opacity: 1, transition: { default: { duration: 1.2, ease }, x: { duration: 0.6, ease } } }
  }
})

const firstLoad = ({ delay = 0, y = 100 } = {}) => Object.assign({
  variants: {
    initial: { y, opacity: 0 },
    exit: { y, opacity: 0 },
    animate: { y: 0, opacity: 1, transition: { duration: 1, ease } }
  }
}, base)

const scale = ({ enter = 0.3, exit = 0.3, ...props } = {}) => Object.assign({
  variants: {
    initial: { scale: 0, opacity: 0, ...props },
    animate: { scale: 1, opacity: 1, ...props, transition: { duration: enter, ease } },
    exit: { scale: 0, opacity: 0, ...props, transition: { duration: exit, ease } }
  }
}, base)

const formAnimation = () => vertical({ enter: 0.6, exit: 0.3 })

const fakeAnimation = (duration = 0) => Object.assign({
  variants: {
    initial: { left: 0 },
    animate: { left: 0, transition: { duration } },
    exit: { left: 0, transition: { duration } }
  }
}, base)

const staggerChildren = (staggerChildren = 0.02, delayChildren = 0.3) => Object.assign({
  variants: { animate: { transition: { staggerChildren, delayChildren } } }
}, base)

const horizontalPanel = ({ duration = 0.6 } = {}) => Object.assign({
  variants: {
    initial: { x: '100%' },
    exit: { x: '100%', overflow: 'hidden', transitionEnd: { overflow: '' }, transition: { duration, ease } },
    animate: { x: '0%', overflow: 'hidden', transitionEnd: { overflow: '' }, transition: { delay: 0.1, duration, ease } }
  }
}, base)

const innerPanel = ({ delay = 0.2, delayParent = 0.1, x = '-100%', y = 100 } = {}) => ({ // Y = X2
  variants: {
    initial: { x, y, opacity: 0 },
    exit: { x, y, opacity: 0.5, transition: { default: { duration: 0.6, ease } } },
    animate: { x: '0%', y: 0, opacity: 1, transition: { default: { duration: 1.2, delay, ease }, x: { duration: 0.6, ease, delay: delayParent }, y: { duration: 0.6, ease, delay } } }
  },
  transformTemplate: ({ x, y }) => `translateX(${x}) translateX(${y})`
})

const verticalPanel = (duration = 0.3, enterDelay = 0.1) => Object.assign({
  variants: {
    initial: { y: '-100%' },
    exit: { y: '100%', transition: { duration, ease } },
    animate: { y: '0%', transition: { delay: enterDelay, duration, ease } }
  }
}, base)

const fade = (duration = 0.3) => Object.assign({
  variants: {
    initial: { opacity: 0 },
    exit: { opacity: 0, transition: { duration, ease } },
    animate: { opacity: 1, transition: { duration, ease } }
  }
}, base)

const willChange = (props) => ((process.browser && detect.safari) ? {} : {
  willChange: 'transform, opacity', transitionEnd: { willChange: null }
})

const pageCardAnimation = (duration = 0, fadeDuration = 0.1) => Object.assign({
  variants: {
    initial: { left: 0, opacity: 0, transition: { default: { duration }, opacity: { duration, ease } } },
    animate: { left: 0, opacity: 1, transition: { default: { duration }, opacity: { duration: fadeDuration, ease } } },
    exit: { left: 0, opacity: 0 }
  }
}, base)

const cardAnimation = ({ incDelay = 0.05, enterDelay = 0.2, distance = 100, exitDistance = 400 } = {}) => Object.assign({
  variants: {
    initial: { x: distance, opacity: 0 },
    exit: ({ i }) => {
      const delay = (i * incDelay)
      return ({
        x: -exitDistance,
        opacity: 0,
        ...willChange('transform, opacity'),
        transition: {
          default: { duration: 0.6, delay, ease: bezier.principleOut },
          opacity: { duration: 0.6, delay, ease: bezier.jacquemus }
        }
      })
    },
    animate: ({ i }) => {
      const delay = enterDelay + (i * incDelay)
      return ({
        x: 0,
        opacity: 1,
        ...willChange('transform, opacity'),
        transition: {
          opacity: { duration: 1.2, delay, ease: bezier.jacquemus },
          x: { duration: 0.6, delay, ease: bezier.jacquemus }
        }
      })
    }
  }
}, base)

const popinAnimation = (mirror = true, duration = 0.4, delay = 0) => Object.assign({
  variants: {
    initial: { y: '100vh' },
    exit: { y: mirror ? '100vh' : '-100vh', transition: { delay: delay, duration, ease } },
    animate: { y: 0, transition: { delay: delay, duration, ease } }
  }
}, base)

const popinAnimationInner = (mirror = false, duration = 0.6, delay = 0) => Object.assign({
  variants: {
    initial: { y: 100, opacity: 0 },
    exit: {
      y: -100,
      opacity: 0,
      ...willChange('transform, opacity'),
      transition: {
        opacity: { duration: 0.6, ease: bezier.jacquemus },
        default: { duration: 0.6, ease: bezier.principleOut }
      }
    },
    animate: {
      y: 0,
      opacity: 1,
      ...willChange('transform, opacity'),
      transition: {
        opacity: { duration: 1.2, ease: bezier.jacquemus },
        default: { duration: 0.6, ease: bezier.jacquemus }
      }
    }
  }
})

const axe = (vertical) => vertical ? 'y' : 'x'
const pageAnimation = ({ distance = 100, exitDistance = 400, inverted = true, delay = 0 } = {}) => {
  return Object.assign({
    variants: {
      initial: ({ vertical, back } = {}) => ({ [axe(vertical)]: invert(distance, back), opacity: 0 }),
      exit: ({ vertical, back } = {}) => ({
        [axe(vertical)]: invert(invert(exitDistance, inverted), back),
        opacity: 0,
        ...willChange('transform, opacity'),
        transition: {
          default: { duration: 0.6, delay, ease: bezier.principleOut },
          opacity: { duration: 0.6, delay, ease }
        }
      }),
      animate: ({ vertical, back } = {}) => ({
        [axe(vertical)]: 0,
        opacity: 1,
        ...willChange('transform, opacity'),
        transition: {
          default: { duration: 0.6, delay, ease },
          opacity: { duration: 1.2, delay, ease }
        }
      })
    }
  }, base)
}

const positionAnimation = { duration: 0.5, easing: bezier.jacquemus }

export {
  base as baseAnimation,
  scale,
  invert,
  vertical,
  firstLoad,
  willChange,
  translateAndFadeIn,
  staggerChildren,
  horizontalPanel,
  verticalPanel,
  fade,
  pageAnimation,
  fakeAnimation,
  positionAnimation,
  cardAnimation,
  pageCardAnimation,
  popinAnimation,
  popinAnimationInner,
  formAnimation,
  innerPanel
}
