import React from 'react'

import styled from '@emotion/styled'

import { motion } from 'framer-motion'

import { mq, rem, sizes } from 'styles/helpers'
import Mask from 'components/mask/Mask'
import Line from 'components/line/Line'
import { connect } from 'helpers/state'

import ContactBlocks from './ContactBlocks'

const Wrapper = styled(motion.div)`
  flex-basis: ${sizes.col(20)};
  box-sizing: border-box;
  padding-left: ${sizes.col(1)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;

  ${mq.tabletP} {
    padding-left: ${sizes.colM(1)};
    position: static;
    flex-basis: auto;
    padding-bottom: ${rem(sizes.paddingY)};
  }
`

const Top = styled(Mask)`
  ${mq.tabletP} {
    position: fixed;
    top: ${rem(sizes.paddingY)};
  }
`

const Agency = ({ mqTabletP, lineTransition, ...props }) => {
  const { delay, duration } = lineTransition
  const delayTop = delay + duration * 0
  const delayBottom = delay + duration * (mqTabletP ? 0 : 0.7)

  return (
    <Wrapper { ...props }>

      <Top delay={ delayTop }>
        Strategy, design and tech.
      </Top>
      <ContactBlocks delay={ delayBottom } />
      {!mqTabletP && (<Line transition={ lineTransition } />)}

    </Wrapper>
  )
}

export default connect('mqTabletP')(Agency)
