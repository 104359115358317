import React from 'react'

import styled from '@emotion/styled'

import { colors, mq, sizes } from 'styles/helpers'
import { connect } from 'helpers/state'
import Line from 'components/line/Line'

const Wrapper = styled.div`
  flex-basis: ${sizes.col(4)};
  text-align: center;
  box-sizing: border-box;
  position: relative;

  ${mq.tabletP} {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: ${sizes.colM(3)};
    background-color: ${colors.white};
  }
`

const Logo = ({ lineTransition, ...props }) => {
  return (
    <Wrapper>
      <Line transition={ lineTransition } />
      P •
    </Wrapper>
  )
}

export default connect()(Logo)
