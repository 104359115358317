import { css } from '@emotion/core'

import map from 'lodash/map'

import math from 'helpers/math'

import easing from './easing'

import { sizes } from './variables'

const snippets = {}

snippets.transition = (properties = 'visibility, opacity', duration = 0.5, ease = easing.quadEaseOut, delay = 0) => css`
  transition: ${map(properties.split(/\s*, \s*/), p => `${p} ${duration}s ${ease} ${delay}s`)
    .join(', ')};
`

snippets.absolute = (properties = 'top, left') => css`
  position: absolute;
  ${map(properties.split(/\s*,\s*/), p => css` ${p}: 0; `)}
`

snippets.underlined = (color, distance = '-0.25em', height = '1px') => css`
  position: relative;

  &::after {
    content: '';
    ${snippets.absolute('left, right')}
    background: ${color};
    height: ${height};
    bottom: ${distance};
  }
`

snippets.keepRatio = (ratio = (2 / 3)) => css`
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-top: ${(1 / ratio) * 100}%;
  }
`

snippets.rem = (px, reference = false) => math.round(px / (reference || sizes.rem.reference), 3) + 'rem'
snippets.correction = (value) => (`calc(${value} * var(--vw-correction))`)

snippets.liquidM = (size, pixels) => snippets.liquid(size, true, pixels)
snippets.liquid = (size, mobile = false, pixels = false) => {
  const result = math.round((size / (sizes.rem[mobile ? 'mobileReference' : 'widthReference'])) * 100, 3)
  if (pixels) return result / 100 * window.innerWidth
  else return result + 'vw'
}

const grid = (reference, columns, appendGutter = false) => {
  const columnWidth = columns * reference.column
  const gutterWidth = (Math.max(0, (columns - 1)) + (appendGutter ? 1 : 0)) * reference.gutter
  return columnWidth + gutterWidth
}

snippets.grid = (columns, appendGutter = false, liquid = true) => {
  const result = grid(sizes.grid, columns, appendGutter)
  if (liquid !== false) return snippets.correction(snippets.liquid(result))
  else return result
}

snippets.gridM = (columns, appendGutter = false, liquid = true) => {
  const result = grid(sizes.gridM, columns, appendGutter)
  if (liquid !== false) return snippets.liquidM(result)
  else return result
}

snippets.gridPercent = (columns, appendGutter = false) => {
  const columnWidth = columns / sizes.grid.total
  const gutterWidth = (Math.max(0, (columns - 1)) + (appendGutter ? 1 : 0)) / (sizes.grid.total - 1)
  return math.round((columnWidth + gutterWidth) / 2 * 100, 3) + '%'
}

snippets.pMargin = css`
  p:first-of-type { margin-top: 0; }
  p:last-of-type { margin-bottom: 0; }
`

snippets.hideText = css`
  text-indent: 99em;
  overflow: hidden;
  white-space: nowrap;
`

snippets.noJs = css`
  .no-js & {
    opacity: 1 !important;
    transform: none !important;
  }
`

snippets.imageDiv = css`
  ${snippets.hideText}
  display: block;
  background-size: contain;
  background-position: center;
`

snippets.fullscreen = css`
  ${snippets.absolute()}
  width: 100%;
  height: 100%;
`

snippets.centerChildren = css`
  display: flex;
  align-items: center;
  justify-content: center;
`

snippets.verticalCenterChildren = css`
  display: flex;
  align-items: center;
`

export default snippets
