import mapValues from 'lodash/mapValues'

const bezier = {}

bezier.linear = [0, 0, 1, 1]

const raw = [0.25, 0.1, 0.25, 1]
const o = { a: raw[0], b: raw[1], c: raw[2], d: raw[3] }
o.e = (o.a + o.b) / 2
o.f = (o.b + o.c) / 2
o.g = (o.c + o.d) / 2
o.h = (o.e + o.f) / 2
o.j = (o.f + o.g) / 2
o.k = (o.h + o.j) / 2

bezier.principleIn = [0.42, 0.1, 1, 1]
bezier.principleOut = [0, 0, 0.58, 1]

bezier.quadEaseIn = [0.55, 0.085, 0.68, 0.53]
bezier.cubicEaseIn = [0.55, 0.055, 0.675, 0.19]
bezier.quartEaseIn = [0.895, 0.03, 0.685, 0.22]
bezier.quintEaseIn = [0.755, 0.05, 0.855, 0.06]
bezier.sineEaseIn = [0.47, 0, 0.745, 0.715]
bezier.expoEaseIn = [0.95, 0.05, 0.795, 0.035]
bezier.circEaseIn = [0.6, 0.04, 0.98, 0.335]

bezier.quadEaseOut = [0.25, 0.46, 0.45, 0.94]
bezier.cubicEaseOut = [0.215, 0.61, 0.355, 1]
bezier.quartEaseOut = [0.165, 0.84, 0.44, 1]
bezier.quintEaseOut = [0.23, 1, 0.32, 1]
bezier.sineEaseOut = [0.39, 0.575, 0.565, 1]
bezier.expoEaseOut = [0.19, 1, 0.22, 1]
bezier.circEaseOut = [0.075, 0.82, 0.165, 1]

bezier.quadEaseInOut = [0.455, 0.03, 0.515, 0.955]
bezier.cubicEaseInOut = [0.645, 0.045, 0.355, 1]
bezier.quartEaseInOut = [0.77, 0, 0.175, 1]
bezier.quintEaseInOut = [0.86, 0, 0.07, 1]
bezier.sineEaseInOut = [0.445, 0.05, 0.55, 0.95]
bezier.expoEaseInOut = [1, 0, 0, 1]
bezier.circEaseInOut = [0.785, 0.135, 0.15, 0.86]

const easing = mapValues(bezier, array => `cubic-bezier(${array.join(',')})`)

export default easing
export { bezier }
