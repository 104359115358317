import snippets from './snippets'

import { rem } from '.'

// Fonts
export const fonts = {}

fonts.mainFontname = 'ABC Diatype Regular'
fonts.main = `'${fonts.mainFontname}', 'Helvetica Neue', 'Arial', sans-serif`
fonts.serifFontname = 'Cent Schbook Mono BT'
fonts.serif = `'${fonts.serifFontname}', 'Helvetica Neue', 'Arial', sans-serif`

// Colours
export const colors = {}

colors.white = '#fff'
colors.black = '#181818'
colors.yellow = '#FAFF00'

// Sizes
export const sizes = {}

sizes.col = (i) => `${i * (100 / 36)}vw`
sizes.colM = (i) => `${i * (100 / 12)}vw`
sizes.paddingY = 40

sizes.rem = { min: 1, max: 1, reference: 1, widthReference: 1440, mobileReference: 375 }
sizes.rem.size = sizes.rem.reference
sizes.rem.sizeMobile = sizes.rem.reference

sizes.paddingTop = 70

export default { fonts, colors, sizes }
