import React from 'react'

import styled from '@emotion/styled'

import { motion } from 'framer-motion'

import { bezier } from 'styles/helpers'

const Wrapper = styled(motion.div)`
  overflow: hidden;
  position: relative;
  backface-visibility: hidden;
`

const Inner = styled(motion.div)`
`

const Mask = ({ children, delay, ...props }) => {
  const variants = {
    initial: {
      translateY: '-110%'
    },
    animate: {
      translateY: '0%',
      transition: {
        duration: 0.5,
        ease: bezier.quadEaseOut
      }
    }
  }

  if (delay) variants.animate.transition.delay = delay

  return (
    <Wrapper { ...props }>
      <Inner className='mask-inner' variants={ variants }>
        {/* <Overlay  /> */}
        {children}
      </Inner>
    </Wrapper>
  )
}

export default Mask
