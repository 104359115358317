import React from 'react'

import styled from '@emotion/styled'

import { motion } from 'framer-motion'

import { mq, rem, sizes, styling } from 'styles/helpers'
import Mask from 'components/mask/Mask'
import { connect } from 'helpers/state'

const Wrapper = styled(motion.div)`
  display: flex;

  ${mq.tabletP} {
    flex-direction: column;
  }
`

const Block = styled(motion.div)`
  flex-basis: ${sizes.col(8)};
  display: flex;
  align-items: flex-start;

  ${mq.tabletP} {
    flex-basis: auto;

    & > * {
      padding-left: 0;
      background-image: none;
    }

    & + & {
      margin-top: ${rem(sizes.paddingY / 2)};
    }
  }
`

const Pre = styled.span`
  display: inline-block;
  width: ${rem(18)};
`

const CustomMask = styled(Mask)`
  /* display: inline-block; */
`

const AddressMask = styled(Mask)`
  display: inline-flex;

  & > * {
    display: inline-block;
  }
`

const Link = styled.a`
  span {
    ${styling.rollMultiline}
  }
`

const Arrow = styled.div`
  width: ${sizes.col(1)};
  background-image: url('/svg/arrow.svg');
  background-position: 0 ${rem(-8)};
  display: block;
  height: ${rem(20)};

  ${mq.tabletP} {
    display: none;
  }
`

const Inner = styled(motion.div)`
`

const ContactBlocks = ({ delay, ...props }) => {
  const stagger = {
    animate: {
      transition: {
        delayChildren: delay,
        staggerChildren: 0.1
      }
    }
  }

  return (
    <Wrapper>
      <Block>
        <Mask delay={ delay }>
          <Arrow />
        </Mask>
        <Inner variants={ stagger }>
          <Link href='https://goo.gl/maps/8G4X8FTXmQV7XuuCA' target='_blank'>
            <span>
              <AddressMask>
                20 Passage Saint-Sébastien
              </AddressMask><br />
              <AddressMask>
                75011 Paris
              </AddressMask>
            </span>
          </Link>
        </Inner>
      </Block>

      <Block>
        <Mask delay={ delay }>
          <Arrow />
        </Mask>
        <Inner variants={ stagger }>
          <CustomMask>
            <Pre>e</Pre>
            <Link href='mailto:office@period.paris' target='_blank'>
              <span>office@period.paris</span>
            </Link>
          </CustomMask>
          <CustomMask>
            <Pre>t</Pre>
            <Link href='tel:+33 1 42 72 73 68' target='_blank'>
              <span>
                +33 1 42 72 73 68
              </span>
            </Link>
          </CustomMask>
        </Inner>
      </Block>
    </Wrapper>
  )
}

export default connect('mqTabletP')(ContactBlocks)
