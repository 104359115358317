import React from 'react'

import styled from '@emotion/styled'

import { motion } from 'framer-motion'

import { bezier, mq, rem, sizes } from 'styles/helpers'

import { baseAnimation } from 'core/animation'

import Logo from './components/Logo'
import Agency from './components/Agency'
import Projects from './components/Projects'

const Page = styled(motion.div)`
  display: flex;
  height: 100%;
  justify-content: stretch;

  & > * {
    padding-top: ${sizes.col(2)};
    padding-bottom: ${sizes.col(1)};

    ${mq.tabletP} {
      padding-top: ${rem(sizes.paddingY)};
      padding-bottom: ${rem(sizes.paddingY)};
    }
  }

  ${mq.tabletP} {
    padding-left: ${sizes.colM(3)};
    flex-direction: column-reverse;
    position: relative;
  }
`

const animation = {
  ...baseAnimation
}

const lineTransition = {
  duration: 1,
  delay: 0.2,
  ease: bezier.quadEaseOut
}

const Home = () => {
  return (
    <Page { ...animation }>
      <Logo lineTransition={ lineTransition } />
      <Agency lineTransition={ lineTransition } />
      <Projects lineTransition={ lineTransition } />
    </Page>
  )
}

export default Home
