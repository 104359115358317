import { css } from '@emotion/core'

import { colors } from './variables'
import { mq } from './mq'
import easing from './easing'

const styling = {}

styling.roll = css`
  position: relative;
  z-index: 0;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    z-index: -1;
    background-color: ${colors.yellow};
    transform: scaleX(0);

    transform-origin: right;
    transition: transform .2s ${easing.quadEaseInOut};
  }

  ${mq.hover} {
    &:hover {
      &::after {
        transform: none;
        transform-origin: left;
      }
    }
  }
`

styling.rollMultiline = css`
  width: calc(100%);
  background-image: linear-gradient(${colors.yellow}  0%, ${colors.yellow} 100%);
  background-repeat: no-repeat;
  background-size: 0% 100%;
  background-position: right;
  transition: background-size .3s ${easing.quadEaseInOut};

  ${mq.hover} {
    &:hover {
      background-size: 100% 100%;
      background-position: left;
    }
  }
`

export default styling
