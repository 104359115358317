import React from 'react'

import styled from '@emotion/styled'

import { motion } from 'framer-motion'

import { mq, rem, sizes, styling, fonts } from 'styles/helpers'
import Mask from 'components/mask/Mask'
import { connect } from 'helpers/state'

const Wrapper = styled(motion.div)`
  padding-left: ${sizes.col(1)};
  padding-right: ${sizes.col(1)};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;

  ${mq.tabletP} {
    height: var(--vh);
    flex-shrink: 0;
    padding-left: ${sizes.colM(1)};
    justify-content: flex-end;
    padding-top: ${rem(sizes.paddingY * 2.5)};
  }
`

const List = styled(motion.ul)`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  z-index: 10000;
  backface-visibility: hidden;
`

const Project = styled(Mask)`
  /* ${styling.roll} */
  display: block;
  font-size: ${rem(16)};
  font-family: ${fonts.serif};

  text-transform: uppercase;

  & + & {
    margin-top: ${rem(4)};
  }
`

const Link = styled.a`
  ${styling.roll}
  align-self: flex-end;

  ${mq.tabletP} {
    position: fixed;
    left: 0;
    z-index: 10;
    bottom: ${rem(sizes.paddingY)};
    width: auto;
    display: flex;
    justify-content: center;
    transform: translateY(50%) rotate(-90deg) translateX(50%);

    span {
      display: block;
      overflow: hidden;
    }
  }
`

const Projects = ({ mqTabletP, lineTransition, ...props }) => {
  const { delay, duration } = lineTransition

  const stagger = {
    animate: {
      transition: {
        delayChildren: delay + (mqTabletP ? 0.3 : 0) * duration,
        staggerChildren: 0.05
      }
    }
  }

  const bottomDelay = delay + (duration * (mqTabletP ? 0.7 : 0.7))

  return (
    <Wrapper { ...props }>
      <List variants={ stagger }>
        <Project>Amina Muaddi</Project>
        <Project>Courrèges</Project>
        {/* <Project>District Vision</Project> */}
        <Project>Division.global</Project>
        {/* <Project>Elie top</Project> */}
        <Project>Estée Lauder</Project>
        <Project>Fursac</Project>
        <Project>Jacquemus</Project>
        <Project>Jean Paul Gaultier</Project>
        <Project>Lacoste</Project>
        {/* <Project>Maison Ullens</Project> */}
        <Project>Marine Serre</Project>
        {/* <Project>Rime Arodaky</Project> */}
        <Project>Rudi Gernreich</Project>
        {/* <Project>Yves rocher</Project> */}
      </List>
      <Link href='https://www.instagram.com/period.paris/' target='_blank'>
        <Mask delay={ bottomDelay }>
          <span>@period.paris</span>
        </Mask>
      </Link>
    </Wrapper>
  )
}

export default connect('mqTabletP')(Projects)
